import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const markets = tv({
	slots: {
		base: 'flex flex-col md:px-4 max-w-[172rem] mx-auto',
		header: 'px-2 md:px-0',
		container: 'mt-2',
		formContainer: 'mt-4 px-2 md:mt-6 text-center md:min-w-[50rem] mx-auto',
		formTitle: 'uppercase max-w-[12ch] mx-auto',
		formStyle: 'flex flex-col mt-2 w-full',
		input: 'rounded-full bg-grey placeholder:text-placeholder placeholder:font-medium text-base px-2 py-[1.2rem] w-full max-w-full',
		checkboxContainer: 'mt-2 ml-2 max-w-[42rem] flex gap-1 text-left cursor-pointer text-placeholder',
		checkbox: 'appearance-none relative z-0 overflow-hidden w-[1.6rem] h-[1.6rem] rounded-[0.4rem] bg-grey shrink-0 checked:bg-blue-800 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-mainPink before:z-10 before:opacity-0 after:content-["✓"] after:inline-flex after:items-center after:justify-center after:absolute after:top-0 after:left-0 after:w-full after:h-full after:text-white after:z-10 after:opacity-0 checked:after:opacity-100 checked:before:opacity-100 after:transition-opacity before:transition-opacity duration-1000 ease-in-out',
	},
})

export type HighlightedCardsVariants = VariantProps<typeof markets>
