import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		wrapper: 'flex justify-center',
		base: 'flex flex-col gap-y-3 lg:grid lg:grid-cols-6 lg:gap-x-4 px-2 md:px-4  w-full',
		illustrationSide:
			'max-w-full relative col-span-4 order-0 aspect-[33.6/26] md:aspect-[9/6] lg:h-full rounded-[0.45rem] md:rounded-m overflow-hidden',
		informationsSide:
			'max-w-full col-span-2 flex flex-col lg:space-x-0 lg:flex-row lg:flex-col md:items-start lg:items-start shrink-0 w-full',
		cardLogoStyle: 'right-1 absolute top-1/2 -translate-y-1/2 w-[19%] h-auto',
		imageStyle: 'w-full h-full object-cover',
	},
	variants: {
		theme: {
			dark: {
				wrapper: 'bg-black',
				base: ' py-5 md:py-6',
			},
			light: {
				wrapper: 'bg-lightGrey',
			},
		},
		alternateSide: {
			true: {
				informationsSide: 'lg:order-2',
				cardLogoStyle: 'md:left-auto md:right-2',
			},
			false: {
				cardLogoStyle: 'md:left-2',
			},
		},
		pairs: {
			true: {
				illustrationSide: 'hidden lg:block',
			},
		},
	},
})

export type ASectionVariants = VariantProps<typeof styles>
