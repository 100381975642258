import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		base: 'grid grid-cols-4 gap-x-2 lg:grid-cols-6 gap-x-2 lg:gap-x-4 lg:gap-x-4 px-2 lg:max-w-[172rem] lg:mx-auto lg:px-4',
		cardsWrapper:
			'col-span-4 flex flex-col gap-y-1 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-3 lg:gap-2 mt-2 lg:mt-0',
		headlineDescWrapper: 'col-span-4 lg:col-span-2',
		header: '[&>h2]:col-span-6 [&>h2>span]:xl:block',

		tagStyle: 'col-span-4 lg:col-span-6',
		cardStyle:
			'flex items-end md:items-start md:flex-col border border-black rounded-[0.8rem] md:rounded-m md:gap-x-[1.5rem] gap-y-[0.5rem] p-2 lg:p-3',

		descriptionStyle: 'hidden lg:block mt-2',
		meaningStyle: 'w-full lg:font-medium',
	},
})

export type KeyFiguresVariants = VariantProps<typeof styles>
