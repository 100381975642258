import { AppTitle } from '@otomoro/components/shared/AppTitle'
import React, { HTMLAttributes } from 'react'
import { SliderCardVariants, sliderCard } from './SliderCard.css'
import { SanityImage } from '@otomoro/components/shared/SanityImage'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'

interface SliderCardProps extends HTMLAttributes<Element>, SliderCardVariants {
	title: string
	thumbnail: SanityImageSource
	theme: 'dark' | 'light'
}

export const SliderCard: React.FC<SliderCardProps> = ({ title, thumbnail }) => {
	const { base, image, contentAndLink, content, titleText, figure } = sliderCard()

	return (
		<div className={base()}>
			<figure className={figure()}>
				<SanityImage
					className={image()}
					image={thumbnail}
					alt=""
					width={810}
					height={840}
					sizes={`
							(max-width: 768px) 100vw,
							(min-width: 1201px) 35vw`}
				/>
			</figure>
			<div className={contentAndLink()}>
				<span className={content()}>
					<AppTitle as="h3" className={titleText()} variant="h3" textTransform="uppercase">
						{title}
					</AppTitle>
				</span>
			</div>
		</div>
	)
}
