import React from 'react'

interface ReChemistryProps {
	className?: string
	theme?: 'dark' | 'light'
}

export const ReChemistry = ({ className, theme="light" }: ReChemistryProps) => {
	return (
		<svg viewBox="0 0 316 39" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				d="M11.2664 11.8915C11.2296 11.6819 11.1979 11.3721 11.1979 10.9888C11.1825 10.7997 11.1825 10.6054 11.1825 10.4531C11.1671 10.2957 11.1457 10.1434 11.1457 10.0013C11.1089 9.58631 11.0088 9.29281 10.836 9.09857C10.6631 8.90947 10.3842 8.78882 10.0222 8.71523C10.4372 8.62624 10.747 8.42087 10.9412 8.10598C11.1303 7.79623 11.2356 7.39748 11.2356 6.89349C11.2356 6.25259 11.0627 5.78539 10.7162 5.4705C10.3696 5.16074 9.90241 5.0033 9.27777 5.0033H6.59521V12.3904H8.06527V9.28254H8.86362C9.17337 9.28254 9.39927 9.37153 9.51992 9.52384C9.64057 9.68129 9.72956 9.90719 9.72956 10.2015L9.76636 11.6083C9.76636 11.7452 9.78176 11.9026 9.80315 12.0233C9.81855 12.1439 9.87161 12.2646 9.94006 12.3698H11.5308V12.3014C11.4101 12.2381 11.3049 12.0969 11.2681 11.8915H11.2664ZM9.44976 7.96993C9.26065 8.12738 8.99796 8.21123 8.63601 8.21123H8.65141H8.0319V6.09514H8.725C9.40269 6.09514 9.74325 6.44169 9.74325 7.11853C9.74325 7.51728 9.64314 7.79623 9.4489 7.96908L9.44976 7.96993Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M307.929 0.619751V16.4438H303.913C302.354 16.4438 301.23 16.0288 300.574 15.1945C299.918 14.3602 299.556 13.1991 299.482 11.7401L299.278 4.57384C299.241 3.84395 299.172 3.13544 299.068 2.45775C298.964 1.78005 298.721 1.17166 298.343 0.651411H290.189V0.961167C290.883 1.34451 291.334 2.06927 291.539 3.11405C291.676 4.1537 291.78 5.66568 291.849 7.66113C291.88 8.66912 291.917 9.58811 291.954 10.4019C291.991 11.2156 292.043 11.9823 292.111 12.6754C292.337 14.7598 292.873 16.2821 293.739 17.2225C294.605 18.1783 295.97 18.7815 297.861 19.0605V19.1657C295.75 19.6175 294.191 20.6409 293.172 22.2213C292.154 23.8018 291.65 25.8494 291.65 28.3643C291.65 31.609 292.516 34.0186 294.265 35.6144C295.998 37.1949 298.434 37.9923 301.568 37.9923H315.45V0.619751H307.932H307.929ZM307.945 32.4518H304.395C300.915 32.4518 299.172 30.7191 299.172 27.2647C299.172 25.2325 299.654 23.7941 300.626 22.9435C301.597 22.093 302.999 21.6575 304.795 21.6575L304.81 21.6729H307.945V32.4518Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M279.962 16.8374H267.436V23.0171H279.962V31.8109H266.674V37.9855H287.491V0.619751H266.139V6.79432H279.962V16.8374Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M225.796 9.95923C226.037 8.72535 226.369 7.75415 226.784 7.07646C227.199 6.39876 227.703 5.94782 228.275 5.72192C228.847 5.49602 229.472 5.37537 230.128 5.37537C230.927 5.37537 231.635 5.54822 232.239 5.87936C232.864 6.21051 233.363 6.86682 233.762 7.82175C234.16 8.79295 234.455 10.1269 234.644 11.8802C234.833 13.613 234.938 15.9071 234.938 18.7316C234.938 21.3885 234.87 23.6253 234.728 25.4624C234.592 27.2841 234.35 28.7593 234.004 29.9042C233.657 31.0491 233.175 31.8629 232.565 32.3669C231.956 32.8709 231.179 33.1284 230.245 33.1284C228.533 33.1284 227.352 32.5038 226.717 31.2699L226.701 31.2331C226.06 29.9992 225.73 28.0252 225.73 25.3007H218.217C218.217 26.6706 218.323 28.1304 218.512 29.6741C218.701 31.2177 219.189 32.6775 219.95 34.0106C220.727 35.3652 221.887 36.4733 223.431 37.3392C224.969 38.2052 226.944 38.6416 229.763 38.6416C232.582 38.6416 234.835 38.153 236.494 37.2032C238.173 36.232 239.434 34.898 240.337 33.1969C241.234 31.4958 241.807 29.4482 242.069 27.054C242.327 24.6598 242.468 22.0765 242.468 19.2314C242.468 16.3862 242.331 13.7661 242.069 11.3874C241.791 9.00943 241.219 6.96179 240.337 5.26071C239.439 3.55962 238.158 2.24188 236.494 1.33914C234.835 0.451798 232.588 0 229.763 0C227.305 0 225.279 0.383344 223.741 1.1449C222.202 1.90645 220.99 2.98289 220.139 4.33743C219.289 5.69197 218.701 7.26727 218.391 9.08901C218.081 10.9107 217.924 12.8163 217.924 14.8486H225.437C225.437 12.8223 225.557 11.1888 225.799 9.96095L225.796 9.95923Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M198.385 23.6839H207.159V37.9651H214.672V0.593384H207.159V17.179H198.385V0.593384H190.873V37.9651H198.385V23.6839Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M179.189 16.817H166.662V22.9908H179.189V31.7854H165.895V37.9651H186.718V0.593384H165.36V6.76796H179.189V16.817Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M145.877 11.5931H145.983L151.311 37.9651H162.473V0.593384H155.585V30.4146H155.48L148.854 0.593384H143.006L136.374 30.4146H136.269V0.593384H129.381V37.9651H140.496L145.877 11.5931Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path d="M124.693 0.593262H117.18V37.9641H124.693V0.593262Z" fill={theme === 'dark' ? '#131313' : '#FF77A8'} />
			<path
				d="M90.7173 17.0949C91.253 17.8932 91.9829 18.6231 92.8805 19.2999C93.7781 19.9768 94.9546 20.6331 96.3768 21.2586L102.173 23.7734C103.785 24.4349 104.819 25.1596 105.307 25.9417C105.79 26.7238 106.032 27.695 106.032 28.8399C106.032 29.3961 105.964 29.9317 105.843 30.452C105.722 30.9714 105.497 31.4394 105.165 31.8382C104.834 32.2369 104.388 32.5681 103.832 32.8094C103.26 33.0507 102.567 33.1713 101.738 33.1713C100.268 33.1713 99.2279 32.6828 98.6032 31.7329C97.9786 30.7618 97.6688 29.4072 97.6688 27.6223H90.4709V28.6619C90.4709 30.4469 90.7652 31.9751 91.3368 33.2398C91.9093 34.5053 92.723 35.5655 93.7413 36.3793C94.7595 37.2144 95.9729 37.8023 97.3899 38.1642C98.8069 38.5262 100.351 38.7204 102.01 38.7204C105.611 38.7204 108.415 37.8014 110.473 35.9959C112.531 34.1742 113.555 31.4181 113.555 27.7215C113.555 26.1779 113.366 24.8233 112.983 23.663C112.605 22.5028 111.995 21.458 111.182 20.5758C110.368 19.6884 109.35 18.9269 108.136 18.25C106.924 17.5723 105.491 16.9639 103.81 16.412C102.566 15.997 101.511 15.5769 100.66 15.1782C99.8097 14.7794 99.1218 14.3276 98.6024 13.845C98.083 13.3564 97.7048 12.8054 97.4789 12.1961C97.253 11.5869 97.1323 10.863 97.1323 10.0278C97.1323 8.44739 97.5995 7.28709 98.5185 6.54179C99.4375 5.7965 100.471 5.41315 101.616 5.41315C102.587 5.41315 103.396 5.55006 104.042 5.82816C104.683 6.10625 105.202 6.4896 105.58 6.97305C105.959 7.46165 106.221 8.0649 106.357 8.77939C106.494 9.48789 106.567 10.2914 106.567 11.1573L106.552 11.2095V12.3014H114.064V10.7945C114.064 7.34014 113.145 4.70465 111.329 2.85125C109.512 0.997856 106.294 0.0788574 102.23 0.0788574C98.1668 0.0788574 94.9957 0.966195 92.7119 2.76654C90.4281 4.55149 89.2832 7.43426 89.2832 11.3712C89.2832 12.5161 89.3885 13.5763 89.593 14.5107C89.8026 15.4503 90.1808 16.3171 90.7165 17.094L90.7173 17.0949Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M88.5912 37.9651V31.7854H80.4426V0.593384H72.9297V31.7854H64.7974V37.9651H88.5912Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M38.6769 3.09282C38.8138 4.13247 38.9182 5.64445 38.9867 7.63989C39.0235 8.64788 39.0551 9.56688 39.0919 10.3806C39.1287 11.1944 39.1758 11.9611 39.2494 12.6542C39.4753 14.7386 40.0109 16.2608 40.8769 17.2012C41.7428 18.157 43.1085 18.7603 44.9987 19.0392V19.1445C42.8877 19.5963 41.3287 20.6197 40.3104 22.2001C39.2921 23.7805 38.7882 25.8282 38.7882 28.343C38.7882 31.5878 39.6541 33.9973 41.4031 35.5932C43.1359 37.1736 45.572 37.9711 48.7063 37.9711H62.588V0.593384H55.0751V16.4174H51.0586C49.4995 16.4174 48.376 16.0024 47.7197 15.1681C47.0634 14.3339 46.7015 13.1727 46.6279 11.7138L46.4182 4.54747C46.3814 3.81758 46.313 3.10908 46.2086 2.43138C46.1042 1.75368 45.862 1.1453 45.4838 0.625044H37.3301V0.934799C38.0232 1.32328 38.475 2.05317 38.6795 3.09282H38.6769ZM47.7651 22.9232C48.7363 22.0726 50.1379 21.6371 51.9339 21.6371L51.9493 21.6525H55.0837V32.4263H51.5343C48.0534 32.4263 46.3104 30.6936 46.3104 27.2392C46.3104 25.2129 46.793 23.7737 47.7651 22.9232Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M24.5242 23.5109H24.6294L24.614 23.5263L29.5068 37.965H37.9703L28.3148 15.3049V0.593262H20.802V15.2887L11.1465 37.9487H19.3002L24.5242 23.5109Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M252.797 36.6673C255.14 36.6673 257.039 34.7633 257.039 32.4146C257.039 30.0659 255.14 28.1619 252.797 28.1619C250.454 28.1619 248.554 30.0659 248.554 32.4146C248.554 34.7633 250.454 36.6673 252.797 36.6673Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M252.797 9.41889C255.14 9.41889 257.039 7.51488 257.039 5.16617C257.039 2.81746 255.14 0.913452 252.797 0.913452C250.454 0.913452 248.554 2.81746 248.554 5.16617C248.554 7.51488 250.454 9.41889 252.797 9.41889Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
			<path
				d="M17.0623 8.92523C17.0623 4.34736 13.3239 0.598633 8.75626 0.598633C4.18865 0.598633 0.450195 4.32597 0.450195 8.92523C0.450195 13.5245 4.18865 17.2732 8.75626 17.2732V17.2578C13.3453 17.2527 17.0623 13.504 17.0623 8.92609V8.92523ZM8.75712 15.5191C5.13931 15.5191 2.1838 12.5525 2.1838 8.92523C2.1838 5.29802 5.12391 2.33052 8.75712 2.33052C12.3903 2.33052 15.3356 5.29716 15.3356 8.92438C15.3356 12.5516 12.3741 15.5182 8.75712 15.5182V15.5191Z"
				fill={theme === 'dark' ? '#131313' : '#FF77A8'}
			/>
		</svg>
	)
}
