import React from 'react'
import { styles } from './AlternateContent.css'
import { Button, SanityButtonProps } from '../Button'
import { PortableTextObject } from 'sanity'
import { Tag } from '../Tag'
import { PortableText, PortableTextComponents, SanityImageAssetDocument } from 'next-sanity'
import { AppText } from '../AppText'
import { resolveHref } from '@otomoro/sanity/lib/sanity.links'
import { AppTitle } from '../AppTitle'
import { DownloadEmail } from '../DownloadEmail/DownloadEmail'
import { ReChemistry } from '@otomoro/components/icons/ReChemistry'
import { SanityImage } from '../SanityImage'
import { DownloadInfoProps } from '../Button/Button'

interface AlternateContentProps {
	_key?: string
	tag?: string
	headline: PortableTextObject
	description?: PortableTextObject
	descriptionType: 'single' | 'multiple'
	descriptionPairs?: any[]
	button?: SanityButtonProps
	theme?: 'dark' | 'light'
	showDL?: boolean
	showLogo?: boolean
	downloadInfo?: DownloadInfoProps
	pairsImage: SanityImageAssetDocument
}

export const AlternateContent = ({
	_key,
	tag,
	headline,
	description,
	descriptionType = 'single',
	descriptionPairs,
	button,
	theme,
	showDL,
	showLogo = false,
	downloadInfo,
	pairsImage,
}: AlternateContentProps) => {
	const {
		tagStyle,
		buttonStyle,
		headlineStyle,
		descriptionStyle,
		headlineTagWrapper,
		descButtonWrapper,
		pairsList,
		pairText,
	} = styles()

	const headlineComponents = {
		block: {
			normal: ({ children }: any) => (
				<AppTitle
					as="h2"
					variant="h1"
					textTransform="uppercase"
					className={headlineStyle({ theme })}
				>
					{children}
				</AppTitle>
			),
		},
		marks: {
			highlight: ({ children }: any) => <span className="text-mainPink">{children}</span>,
		},
	} as PortableTextComponents

	const descComponents = {
		block: {
			normal: ({ children }: any) => (
				<AppText as="p" size="base" className={descriptionStyle({ theme })} color="black">
					{children}
				</AppText>
			),
		},
		marks: {
			highlight: ({ children }: any) => <span className="text-mainPink">{children}</span>,
		},
	} as PortableTextComponents

	return (
		<>
			<div className={headlineTagWrapper()}>
				<Tag className={tagStyle()} theme={theme}>
					{tag}
				</Tag>
				{showLogo && <ReChemistry className="mt-[1.3rem] w-[26.5rem] md:w-[31.5rem]" />}
				<PortableText value={headline} components={headlineComponents} />
			</div>

			{descriptionType !== 'single' && (
				<SanityImage
					className="w-full h-full object-cover lg:hidden mt-2"
					image={pairsImage?.image}
					alt=""
					width={pairsImage?.width}
					height={pairsImage?.height}
				/>
			)}
			<div className={descButtonWrapper({ pairs: descriptionType !== 'single' })}>
				{descriptionType === 'single' ? (
					<PortableText value={description as any} components={descComponents} />
				) : (
					<>
						{descriptionPairs?.map((pair, idx) => (
							<div key={`pair-${idx}`} className={pairsList({ theme })}>
								<AppText
									className={pairText({ theme })}
									as="strong"
									textTransform="uppercase"
									size="h3"
									family="condensed"
								>
									{pair.name}
								</AppText>
								<AppText className={pairText({ theme })} size="base">
									{pair.value}
								</AppText>
							</div>
						))}
					</>
				)}
				{button && (
					<Button
						as="nextLink"
						variant="primary"
						href={
							button._type === 'linkInternal'
								? resolveHref(button.pageType, button.slug)
								: button.url
						}
						target={button._type === 'linkExternal' && button.newWindow ? '_blank' : undefined}
						className={buttonStyle()}
					>
						{button.title}
					</Button>
				)}
			</div>

			{showDL && downloadInfo && _key && theme && (
				<span className="hidden lg:block">
					<DownloadEmail _key={_key} infos={downloadInfo} theme={theme} />
				</span>
			)}
		</>
	)
}
