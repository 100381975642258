import { PortableTextObject } from 'sanity'
import { styles, ASectionVariants } from './AlternateSection.css'
import { SanityImageAssetDocument } from 'next-sanity'
import { SanityButtonProps } from '@otomoro/components/shared/Button'
import { SanityImage } from '@otomoro/components/shared/SanityImage'
import { AlternateContent } from '@otomoro/components/shared/AlternateContent/AlternateContent'
import { DownloadEmail } from '@otomoro/components/shared/DownloadEmail/DownloadEmail'

interface AlternateSectionProps extends ASectionVariants {
	_key: string
	tag: string
	headline: PortableTextObject
	description?: PortableTextObject
	descriptionType: 'single' | 'multiple'
	descriptionPairs: any[]
	button: SanityButtonProps
	image: SanityImageAssetDocument
	theme: 'dark' | 'light'
	alternateSide: boolean
	showDL: boolean
	downloadInfo: {
		title: string
		fileUrl: string
		fileName: string
		tag: string
	}
	showLogo: boolean
	cardLogo: any
}

export const AlternateSection: React.FC<AlternateSectionProps> = ({
	_key,
	tag,
	headline,
	description,
	button,
	image,
	theme,
	alternateSide,
	descriptionType,
	descriptionPairs,
	showDL,
	downloadInfo,
	showLogo,
	cardLogo,
}) => {
	const { wrapper, base, illustrationSide, informationsSide, cardLogoStyle, imageStyle } = styles()

	return (
		<div className={wrapper({ theme })}>
			<section className={base({ theme })}>
				<div className={informationsSide({ alternateSide })}>
					<AlternateContent
						_key={_key}
						tag={tag}
						headline={headline}
						theme={theme}
						button={button}
						descriptionPairs={descriptionPairs}
						description={description}
						descriptionType={descriptionType}
						showDL={showDL}
						downloadInfo={downloadInfo}
						showLogo={showLogo}
						pairsImage={image}
					/>
				</div>
				<div className={illustrationSide({ pairs: descriptionType !== 'single' })}>
					{cardLogo?.svg && (
						<div className={cardLogoStyle({ alternateSide })}>
							<span dangerouslySetInnerHTML={{ __html: cardLogo.svg }} />
						</div>
					)}
					<SanityImage
						className={imageStyle()}
						image={image.image}
						alt={image.alt || ''}
						sizes='(max-width: 768px) 80vw, (max-width: 1200px) 50vw, 60vw'
					/>
				</div>

				{showDL && (
					<span className="block lg:hidden">
						<DownloadEmail _key={_key} infos={downloadInfo} theme={theme} center noPx altMt />
					</span>
				)}
			</section>
		</div>
	)
}
