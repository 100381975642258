import { pageWrapper, PageWrapperVariants } from './PageWrapper.css'

interface PageWrapperProps extends PageWrapperVariants {
	children: React.ReactNode
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
	theme = 'light',
	noPbMobile,
	noPb,
	lowerPb,
	children,
}) => {
	const { base } = pageWrapper({ theme, noPbMobile, noPb, lowerPb })

	return <main className={base()}>{children}</main>
}
