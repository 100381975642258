'use client'

import React, { HTMLAttributes } from 'react'
import { AppTitle } from '@otomoro/components/shared/AppTitle'
import { Button } from '@otomoro/components/shared/Button'
import { Tag } from '@otomoro/components/shared/Tag'
import { ProductCard } from '../ProductCard'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { usePrevNextButtons } from '@otomoro/helpers/prevNextButtons'
import { CardsVariants, cards } from './CardsSlider.css'
import { PortableText, PortableTextComponents } from 'next-sanity'
import { Arrow } from '@otomoro/components/icons/Arrow'
import { SliderCard } from '../SliderCard/SliderCard'

interface ProductsProps extends HTMLAttributes<Element>, CardsVariants {
	cardsList: Array<any>
	headline: any
	title: string
	theme: 'dark' | 'light'
}

export const CardsSlider: React.FC<ProductsProps> = ({
	title,
	headline,
	cardsList,
	theme = 'dark',
}) => {
	const { base, tag, contentContainer, titleText, carousel, viewport, controls } = cards()

	const [emblaRef, emblaApi] = useEmblaCarousel({}, [WheelGesturesPlugin()])

	const components = {
		block: {
			normal: ({ children }: any) => (
				<AppTitle as="h2" textTransform="uppercase" className={titleText({ theme })} color="black">
					{children}
				</AppTitle>
			),
		},
		marks: {
			highlight: ({ children }: any) => <span className="text-mainPink">{children}</span>,
		},
	} as PortableTextComponents

	const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
		usePrevNextButtons(emblaApi)

	return (
		<section className={base({ theme })}>
			<Tag as="strong" className={tag()} theme={theme}>
				{title}
			</Tag>
			<div className={contentContainer()}>
				<PortableText value={headline} components={components} />
				<div className={controls()}>
					<Button
						onClick={onPrevButtonClick}
						disabled={prevBtnDisabled}
						variant={theme === 'dark' ? 'outline_white' : 'outline_black'}
						single
					>
						<Arrow orientation="left" color={prevBtnDisabled ? '#575757' : '#fff'} />
						<span className="sr-only">Previous slide</span>
					</Button>
					<Button
						onClick={onNextButtonClick}
						disabled={nextBtnDisabled}
						variant={theme === 'dark' ? 'outline_white' : 'outline_black'}
						single
					>
						<Arrow orientation="right" color={nextBtnDisabled ? '#575757' : '#fff'} />
						<span className="sr-only">Next slide</span>
					</Button>
				</div>
			</div>
			<div className={viewport()} ref={emblaRef}>
				<div className={carousel()}>
					{cardsList?.map((product) => {
						if (product?.slug) {
							return (
								<ProductCard
									theme={theme}
									key={product._key}
									title={product.formula}
									description={product.title}
									productLogo={product.productLogo}
									hoverThumbnail={product.marketThumbnail?.image}
									slug={product.slug}
									type={product._type}
								/>
							)
						}

						return (
							<SliderCard
								key={product._key}
								title={product.title}
								thumbnail={product.image.image}
								theme={theme}
							/>
						)
					})}
				</div>
			</div>
		</section>
	)
}
