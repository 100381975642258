import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const cards = tv({
	slots: {
		base: 'flex flex-col',
		tag: 'ml-2 md:ml-4',
		contentContainer: 'mt-1 flex justify-between gap-3 md:gap-4 px-2 md:px-4',
		explanation: 'flex items-end content-end justify-end md:px-4',
		titleText: 'text-white',
		explanationInner: 'lg:max-w-[45rem] gap-2 flex flex-col ',
		bodyText: 'text-white',
		viewport: 'overflow-hidden',
		carousel: 'flex gap-2 md:gap-[4rem] mt-2 mx-2 md:mx-4',
		controls: 'hidden md:flex items-center gap-[1rem]',
	},
	variants: {
		theme: {
			dark: {
				base: 'bg-black py-4 md:py-6',
				titleText: 'text-white',
			},
			light: {
				base: 'bg-lightGrey',
				titleText: 'text-black',
			},
		},
	},
})

export type CardsVariants = VariantProps<typeof cards>
