import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		base: 'bg-mainPink px-2 py-5 md:py-[18.8rem] flex flex-col  md:items-center',

		tagStyle: '',
		buttonStyle: 'mt-2 md:mt-4 w-fit',

		headlineStyle: 'mt-1 [&>br]:hidden [&>br]:lg:block md:text-center',
		descriptionStyle: 'md:text-center mt-1 [&>br]:hidden [&>br]:md:block',
	},
})

export type HSectionVariants = VariantProps<typeof styles>
