import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const pageWrapper = tv({
	slots: {
		base: 'pb-5 lg:pb-7',
	},
	variants: {
		theme: {
			light: {
				base: 'bg-lightGrey',
			},
			dark: {
				base: 'bg-black',
			},
		},
		noPbMobile: {
			true: 'pb-0',
		},
		lowerPb: {
			true: 'lg:pb-4',
		},
		noPb: {
			true: 'pb-0 lg:pb-0',
		}
	},
})

export type PageWrapperVariants = VariantProps<typeof pageWrapper>
