import { AppText } from '@otomoro/components/shared/AppText'
import { AppTitle } from '@otomoro/components/shared/AppTitle'
import { Button } from '@otomoro/components/shared/Button'
import React, { HTMLAttributes } from 'react'
import { ProductCardVariants, productCard } from './ProductCard.css'
import { AppLink } from '@otomoro/components/shared/AppLink'
import { SanityImage } from '@otomoro/components/shared/SanityImage'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { resolveHref } from '@otomoro/sanity/lib/sanity.links'
import { Arrow } from '@otomoro/components/icons/Arrow'

interface ProductCardProps extends HTMLAttributes<Element>, ProductCardVariants {
	title: string
	description: string
	productLogo: any
	hoverThumbnail: SanityImageSource
	slug: string
	type: string
	theme: 'dark' | 'light'
}

export const ProductCard: React.FC<ProductCardProps> = ({
	type,
	title,
	description,
	productLogo,
	hoverThumbnail,
	slug,
	theme = 'dark',
}) => {
	const {
		base,
		hoverImage,
		contentAndLink,
		content,
		titleText,
		descriptionText,
		button,
		figure,
		svgLogo,
	} = productCard()

	return (
		<AppLink href={resolveHref(type, slug)} className={base()}>
			<div>
				<figure className={figure()}>
					<div className={svgLogo()} dangerouslySetInnerHTML={{ __html: productLogo?.svg }} />
					{hoverThumbnail && (
						<SanityImage
							className={hoverImage()}
							image={hoverThumbnail}
							alt=""
							width={810}
							height={840}
							sizes={`
								(max-width: 768px) 100vw,
								(min-width: 1201px) 35vw`}
						/>
					)}
				</figure>
				<div className={contentAndLink()}>
					<span className={content()}>
						<AppTitle
							as="h3"
							className={titleText({ theme })}
							variant="h3"
							textTransform="uppercase"
						>
							{title}
						</AppTitle>
						<AppText as="p" size="base" weight="medium" className={descriptionText({ theme })}>
							{description}
						</AppText>
					</span>
				</div>
			</div>
		</AppLink>
	)
}
