import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		viewport: 'overflow-hidden',
		carousel: 'flex gap-2 mt-2 mx-2 md:mx-0 md:grid md:grid-cols-2 md:gap-2 lg:gap-4 [&>*]:max-md:w-fit',
	},
	variants: {
		expanded: {
			true: {
				carousel: 'lg:!grid-cols-3',
			},
			false: ''
		}
	},
})

export type StylesVariants = VariantProps<typeof styles>
