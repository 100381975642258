import { PortableTextObject } from 'sanity'
import { styles, HSectionVariants } from './HighlightedSection.css'
import { AppTitle } from '@otomoro/components/shared/AppTitle'
import { PortableText, PortableTextComponents } from 'next-sanity'
import { AppText } from '@otomoro/components/shared/AppText'
import { Tag } from '@otomoro/components/shared/Tag'
import { Button, SanityButtonProps } from '@otomoro/components/shared/Button'
import { resolveHref } from '@otomoro/sanity/lib/sanity.links'

interface HighlightedSectionProps extends HSectionVariants {
	tag: string
	headline: PortableTextObject
	description?: PortableTextObject
	button: SanityButtonProps
}

export const HighlightedSection: React.FC<HighlightedSectionProps> = ({
	tag,
	headline,
	description,
	button,
}) => {
	const { base, tagStyle, buttonStyle, headlineStyle, descriptionStyle } = styles()

	const headlineComponents = {
		block: {
			normal: ({ children }: any) => (
				<AppTitle
					as="strong"
					variant="headline"
					textTransform="uppercase"
					className={headlineStyle()}
					color="black"
				>
					{children}
				</AppTitle>
			),
		},
		marks: {
			highlight: ({ children }: any) => <span className="text-white">{children}</span>,
		},
	} as PortableTextComponents

	const descComponents = {
		block: {
			normal: ({ children }: any) => (
				<AppText as="p" size="intro" className={descriptionStyle()} weight="medium" color="black">
					{children}
				</AppText>
			),
		},
		marks: {
			highlight: ({ children }: any) => <span className="text-white">{children}</span>,
		},
	} as PortableTextComponents

	return (
		<section className={base()}>
			<Tag className={tagStyle()}>{tag}</Tag>
			<PortableText value={headline} components={headlineComponents} />
			{description && <PortableText value={description} components={descComponents} />}
			{button && (
				<Button
					as="nextLink"
					variant="secondary"
					href={
						button._type === 'linkInternal' ? resolveHref(button.pageType, button.slug) : button.url
					}
					target={button._type === 'linkExternal' && button.newWindow ? '_blank' : undefined}
					className={buttonStyle()}
				>
					{button.title}
				</Button>
			)}
		</section>
	)
}
