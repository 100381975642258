import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const sliderCard = tv({
	slots: {
		base: 'relative group flex-[0_0_calc(100%/1.43)] md:flex-[0_0_calc(100%/2.25-6.25rem)] lg:flex-[0_0_calc(100%/2-8rem)] xl:flex-[0_0_calc(100%/3.25-6.25rem)] min-w-[0] flex flex-col flex-shrink-0',
		image: 'w-full h-full object-cover',
		contentAndLink: 'flex justify-between mt-[2rem] gap-x-2',
		content: 'flex flex-col flex-grow-0',
		titleText: 'w-fit group-hover:text-mainPink',
		figure: 'rounded-l overflow-hidden relative aspect-[35/40]',
	},
	variants: {
		theme: {
			dark: {
				titleText: 'text-white',
			},
			light: {
				titleText: 'text-black',
			},
		},
	},
})

export type SliderCardVariants = VariantProps<typeof sliderCard>
