'use client'

import { PortableTextObject } from 'sanity'
import { styles, KeyFiguresVariants } from './KeyFigures.css'
import { AppTitle } from '@otomoro/components/shared/AppTitle'
import { PortableText, PortableTextComponents } from 'next-sanity'
import { AppText } from '@otomoro/components/shared/AppText'
import { Tag } from '@otomoro/components/shared/Tag'
import { useRef, createRef } from 'react'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SectionHeader } from '@otomoro/components/shared/SectionHeader'

gsap.registerPlugin(ScrollTrigger)

interface KeyFiguresProps extends KeyFiguresVariants {
	tag: string
	headline: PortableTextObject
	description?: PortableTextObject
	cards: {
		number: number
		meaning: PortableTextObject
		isPercent: boolean
	}[]
}

export const KeyFigures: React.FC<KeyFiguresProps> = ({ tag, headline, cards }) => {
	const { base, cardsWrapper, headlineDescWrapper, cardStyle, meaningStyle, header } = styles()

	const meaningComponents = {
		block: {
			normal: ({ children }: any) => (
				<AppText size="large" as="p" weight="medium" className={meaningStyle()}>
					{children}
				</AppText>
			),
		},
		marks: {
			highlight: ({ children }: any) => <span className="text-mainPink">{children}</span>,
		},
	} as PortableTextComponents

	const numberRefs = useRef(cards.map(() => createRef<Element>()))

	useGSAP(() => {
		cards.forEach((card, index) => {
			const el = numberRefs.current[index].current as gsap.DOMTarget
			if (el) {
				gsap.fromTo(
					el,
					{ innerText: 0 },
					{
						innerText: card.number,
						duration: 2,
						snap: 'innerText',
						ease: 'power3.inOut',
						scrollTrigger: {
							trigger: el,
							start: 'top bottom', // Animation starts when the top of the element hits the bottom of the viewport
							toggleActions: 'play none none none',
						},
					}
				)
			}
		})
	})

	return (
		<section className={base()}>
			<div className={headlineDescWrapper()}>
				<SectionHeader className={header()} tag={tag} title={headline} noPx />
			</div>
			<div className={cardsWrapper()}>
				{cards &&
					cards.map((card, index) => (
						<div key={index} className={cardStyle()}>
							<AppTitle
								className={`leading-[0.8] md:leading-[1] w-[11.5rem] shrink-0 md:w-[50%] ${card.isPercent ? "after:content-['%']" : ''}`}
								as="strong"
								variant="headlineSmall"
								ref={numberRefs.current[index]}
							>
								{card.number}
							</AppTitle>
							<PortableText value={card.meaning} components={meaningComponents} />
						</div>
					))}
			</div>
		</section>
	)
}
