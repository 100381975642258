'use client'

import React, { useEffect } from 'react'
import { AppText } from '../AppText'
import { Button } from '../Button'
import { Arrow } from '@otomoro/components/icons/Arrow'
import { styles } from './DownloadEmail.css'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Inputs, downloadValidationSchema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { sendEmail } from './actions'
import clsx from 'clsx'
import { AppTitle } from '../AppTitle'
import { DownloadInfoProps } from '../Button/Button'

interface DownloadProps {
	_key: string
	infos: DownloadInfoProps
	theme: 'dark' | 'light'
	center?: boolean
	noPx?: boolean
	altMt?: boolean
	noMaxWidth?: boolean
	noMt?: boolean
	className?: string
}

export const DownloadEmail = ({
	_key,
	infos,
	theme,
	center = false,
	noPx = false,
	altMt = false,
	noMaxWidth = false,
	noMt = false,
	className,
}: DownloadProps) => {
	const { title, fileName, fileUrl, tag } = infos

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitSuccessful, isSubmitting },
		reset,
		setError,
	} = useForm<Inputs>({
		resolver: yupResolver(downloadValidationSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		const res = await sendEmail(data, fileName, fileUrl, tag)

		if (res?.status && res?.status !== 'success') {
			setError('email', {
				type: 'server',
				message: res?.message,
			})
		}
	}

	useEffect(() => {
		if (!isSubmitSuccessful) return
		setTimeout(() => {
			reset()
		}, 3000)
	}, [isSubmitSuccessful, reset])

	const {
		inputEmail,
		inputCheck,
		inputEmailWrapper,
		newsletterWrapper,
		honeypot,
		base,
		titleStyle,
		formStyle,
	} = styles()

	return (
		<div className={clsx(base({ center, noPx, altMt, noMaxWidth, noMt }), className)}>
			<AppTitle
				color={theme === 'dark' ? 'white' : 'black'}
				textTransform="uppercase"
				className={titleStyle({ center })}
			>
				{title}
			</AppTitle>
			<form className={formStyle({ center })} onSubmit={handleSubmit(onSubmit)}>
				<div className="relative">
					<div className={inputEmailWrapper()}>
						<input
							placeholder="E-mail"
							type="text"
							disabled={isSubmitting}
							id=""
							className={clsx(
								inputEmail({ theme }),
								errors.email?.message ? '!border-redError' : '',
								isSubmitSuccessful ? '!border-mainPink' : ''
							)}
							{...register('email')}
						/>
						<Button type="submit" single>
							<Arrow orientation="top-right" height={10} width={15} color="black" />
						</Button>
					</div>
					{errors.email?.message && (
						<span className="block text-redError text-s mt-[0.5rem]">{errors.email?.message}</span>
					)}
					{isSubmitSuccessful && (
						<span className="block text-mainPink text-s mt-[0.5rem]">
							File submitted. Thank you!
						</span>
					)}
				</div>
				<div className={newsletterWrapper({ center })}>
					<input
						className={clsx(inputCheck({ theme }), errors.subscribe ? '!bg-redError/75' : '')}
						type="checkbox"
						id={`subscribe-${_key}`}
						{...register('subscribe')}
					/>
					<AppText
						className={clsx(
							'cursor-pointer max-w-[85%] lg:max-w-[44rem]',
							errors.subscribe ? '!text-redError/75' : ''
						)}
						as="label"
						htmlFor={`subscribe-${_key}`}
						size="notice"
						color="textGrey"
						weight="medium"
					>
						Stay updated! Subscribe to our newsletter for the latest news, exclusive offers, and
						sustainable innovation insights. Join our community today!
					</AppText>
				</div>

				{/* Honeypot */}
				<input
					type="text"
					id="placeOfBirth"
					autoComplete="off"
					className={honeypot()}
					placeholder="Place of Birth"
					{...register('placeOfBirth')}
				/>
				{/* Honeypot */}
			</form>
		</div>
	)
}
