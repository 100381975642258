import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		base: 'mt-2',
		inputEmail:
			'bg-midGrey placeholder:text-oldSilver py-[0.9rem] md:py-[1.3rem] rounded-full w-full text-input-mobile md:text-base color-lightGrey px-2 text-black focus:outline-none transition-colors duration-75 border border-transparent',
		inputCheck:
			'relative cursor-pointer appearance-none w-[1.6rem] h-[1.6rem] bg-midGrey before:content-[""] before:left-[10%] before:top-[10%] before:w-[80%] before:h-[80%] before:opacity-0 before:absolute before:[clip-path:polygon(14%_44%,0_65%,50%_100%,100%_16%,80%_0%,43%_62%)] before:bg-mainPink before:transition-opacity checked:before:opacity-100 rounded-[0.4rem] flex-shrink-0 transition-colors duration-75',
		inputEmailWrapper: 'flex space-x-1 items-center',
		newsletterWrapper: 'flex space-x-1 items-center lg:items-start mt-[1.5rem] lg:mt-1',
		honeypot: 'opacity-0 absolute top-0 left-0 h-0 w-0 z-[-1]',
		titleStyle: '',
		formStyle: 'mt-1',
	},
	variants: {
		theme: {
			dark: {
				inputEmail: 'bg-darkGrey text-white focus:bg-onyx',
				inputCheck: 'bg-darkGrey',
			},
			light: {
				inputEmail: 'bg-midGrey text-black focus:bg-midGrey',
				inputCheck: 'bg-midGrey',
			},
		},
		center: {
			true: {
				base: '!mt-5 lg:!mt-6 px-2 md:px-0 max-w-[40rem] md:max-w-[50rem] w-full mx-auto',
				newsletterWrapper: '!mt-2',
				titleStyle: '!text-h2-mobile md:!text-h1 text-center whitespace-pre-line',
				formStyle: '!mt-2',
			},
			false: {
				titleStyle: '!text-h3-mobile md:!text-h3',
			},
		},
		noPx: {
			true: {
				base: '!px-0',
			},
		},
		altMt: {
			true: {
				base: '!mt-3',
			},
		},
		noMaxWidth: {
			true: {
				base: '!max-w-full',
			},
		},
		noMt: {
			true: {
				base: '!mt-0 lg:!mt-0',
			},
		},
	},
})

export type DLEmailVariants = VariantProps<typeof styles>
