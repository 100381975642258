import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		tagStyle: '',
		buttonStyle: 'mt-[1.5rem] lg:mt-2',
		headlineTagWrapper: 'w-full max-w-[38rem] md:mb-3',
		descButtonWrapper: 'mt-auto',

		headlineStyle: 'mt-1',
		descriptionStyle: 'mt-[1.5rem] md:mt-0',

		pairsList:
			'flex flex-col space-y-[0.5rem] lg:items-center lg:space-y-0 lg:space-x-2 lg:flex-row lg:justify-between p-2 lg:px-0 border rounded-[0.8rem] lg:rounded-none lg:border-l-[0px] lg:border-r-[0px] lg:border-t-[0px] lg:first:border-t mt-2',
		pairText: '',
	},
	variants: {
		theme: {
			dark: {
				headlineStyle: 'text-white mt-1',
				descriptionStyle: 'text-white',
				pairsList: 'border-white first:border-white text-white',
				pairText: 'text-white',
			},
			light: {
				headlineStyle: 'text-black mt-1',
				descriptionStyle: 'text-black',
				pairsList: 'border-black first:border-black text-black',
				pairText: 'text-black',
			},
		},
		pairs: {
			true: {
				descButtonWrapper: 'flex flex-col space-y-1 lg:space-y-0 w-full',
			},
			false: {
				descButtonWrapper: '',
			},
		},
	},
})

export type ASectionVariants = VariantProps<typeof styles>
