import * as yup from 'yup'

export const downloadValidationSchema = yup.object().shape({
	email: yup
		.string()
		.email('Your input do not match the email format.')
		.required('An email is required'),
	subscribe: yup.bool().oneOf([true]),
	placeOfBirth: yup.string().optional(),
})

export type Inputs = yup.InferType<typeof downloadValidationSchema>
