import React, { HTMLAttributes, ReactNode, use, useEffect, useRef, useState } from 'react'
import { styles, StylesVariants } from './Card.css'
import { AppTitle } from '@otomoro/components/shared/AppTitle'
import clsx from 'clsx'
import { AppText } from '@otomoro/components/shared/AppText'
import { resolveHref } from '@otomoro/sanity/lib/sanity.links'
import { AppLink } from '@otomoro/components/shared/AppLink'
import { SanityImage } from '@otomoro/components/shared/SanityImage'
import { motion } from 'framer-motion'
import { SanityImageSource } from "@sanity/image-url/lib/types/types"

interface CardProps extends HTMLAttributes<Element>, StylesVariants {
	title: string,
	description?: string,
	image: SanityImageSource & {
		lqip?: string | undefined;
		url?: string | undefined;
		filename?: string | undefined;
		alt?: string | undefined;
		width?: number | undefined;
		height?: number | undefined;
	},
	link: {
		title: string,
		reference: {
			_type: string,
			slug: string,
		}
		anchor?: string
	}
}

export const Card: React.FC<CardProps> = ({
	title,
	description,
	image,
	link,
	className,
}) => {
	const { base, titleText, image: imageStyle, bodyText, content, link: linkStyle } = styles()

	return (
		<motion.div
			whileHover="hover"
			className={clsx(base(), className)}
		>
			<SanityImage
				className={imageStyle()}
				image={image}
				alt={image.alt || ""}
				width={image.width}
				height={image.height}
				sizes='(max-width: 768px) 80vw, (max-width: 1200px) 50vw, 60vw'
			/>
			<div className={content()}>
				<AppTitle
					as="h3"
					className={titleText()}
					variant="h2">
					{title}
				</AppTitle>
				<motion.div
					className="h-auto xl:h-0 overflow-hidden xl:opacity-0"
					variants={{
						hover: { height: 'auto', opacity: 1 },
					}}
					animate={{
						transition: { duration: 0.8, ease: 'easeInOut' }
					}}
				>
					<div className="h-max">
						{description && <AppText as="p" className={clsx(bodyText(), "!text-s-mobile md:!text-base")}>{description}</AppText>}
						{link && (
							<AppLink
								arrow={true}
								className={linkStyle()}
								aria-label={`More information on ${title}`}
								textProps={{
									size: 'button',
									color: 'mainPink',
									family: 'familjen',
									decoration: 'underline'
								}}
								href={resolveHref(link.reference._type, link.reference.slug, link.anchor)}>
									{link.title}
							</AppLink>
						)}
					</div>
				</motion.div>
			</div>
		</motion.div>
	)
}
