import React, { HTMLAttributes } from 'react'
import { HighlightedCardsVariants, markets } from './HighlightedCards.css'
import { PortableTextObject } from 'sanity'
import { HighlightedCardsSlider } from '@otomoro/components/shared/HighlightedCardsSlider'
import { SanityAsset } from "@sanity/image-url/lib/types/types"
import { SectionHeader } from '@otomoro/components/shared/SectionHeader'
import { DownloadInfoProps } from '@otomoro/components/shared/Button/Button'
import { DownloadEmail } from '@otomoro/components/shared/DownloadEmail/DownloadEmail'

interface HighlightedCardsProps extends HTMLAttributes<Element>, HighlightedCardsVariants {
	title: string
	headline: PortableTextObject
	expandedMarkets: boolean
	cards: {
		title: string
		description?: string
		image: SanityAsset
		link: {
			title: string
			reference: {
				_type: string
				slug: string
			}
		}
	}[],
	formOption?: boolean,
	downloadInfo: DownloadInfoProps
	_key: string
}

export const HighlightedCards: React.FC<HighlightedCardsProps> = ({ title, headline, cards, expandedMarkets, formOption, downloadInfo, _key }) => {

	const { base, header, container } = markets()

	return (
		<section className={base()}>
			<SectionHeader className={header()} title={headline} tag={title} noPx/>
			<div className={container()}>
				<HighlightedCardsSlider cards={cards} expanded={expandedMarkets} />
			</div>
			{formOption && downloadInfo && (
				<DownloadEmail _key={_key} infos={downloadInfo} theme="light" center />
			)}
		</section>
	)
}
