import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		base: 'flex-[0_0_calc(100%/1.1)] h-full overflow-hidden relative rounded-m group min-h-[45rem] md:min-h-[60rem] max-md:w-[30rem]',
		image: 'absolute bottom-0 left-0 h-full w-full object-cover',
		content: 'absolute bottom-2 left-2 z-[1] mt-auto w-[calc(100%-4rem)] rounded-m p-2 md:p-3 bg-white overflow-hidden',
		titleText: 'uppercase',
		bodyText: 'pt-1 mb-2 max-w-[55ch] text-s-mobile md:text-base xl:opacity-0 group-hover:lg:opacity-100 group-focus-within:lg:opacity-100 transition-opacity duration-500 ease-in-out',
		link: 'before:absolute before:top-0 before:left-0 before:w-full before:h-full before:inline-block underline decoration-mainPink underline-offset-1 xl:opacity-0 group-hover:lg:opacity-100 group-focus-within:lg:opacity-100 transition-opacity duration-500 ease-in-out',
	},
})

export type StylesVariants = VariantProps<typeof styles>
