'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'
import { A11y, Keyboard, FreeMode } from 'swiper/modules'
import { useState } from 'react'

import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/free-mode'
import 'swiper/css/keyboard'
import clsx from "clsx"
import { Card } from '@otomoro/components/home/HighlightedCards/Card'
import { SanityAsset } from "@sanity/image-url/lib/types/types"

import { styles } from './HighlightedCardsSlider.css'
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures"
import useEmblaCarousel from "embla-carousel-react"

type Props = SwiperOptions & {
	cards: {
		title: string,
		description?: string,
		image: SanityAsset,
		link: {
			title: string,
			reference: {
				_type: string,
				slug: string,
			}
		}
	}[],
	className?: string
	expanded?: boolean
}

export function HighlightedCardsSlider({
	cards,
	className,
	expanded = false,
}: Props) {
	const { carousel, viewport } = styles({expanded})

	const [emblaRef, emblaApi] = useEmblaCarousel({
		breakpoints: {
			'(min-width: 768px)': {
				active: false
			}
		},
	}, [WheelGesturesPlugin()])

	return (
		<div className={clsx(viewport(), className)} ref={emblaRef}>
			<div className={carousel()}>
				{cards?.map((card, idx: number) => (
					<Card
						key={idx}
						title={card?.title}
						description={card.description}
						image={card.image}
						link={card.link}
					/>
				))}
			</div>
		</div>
	)
}
