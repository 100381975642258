import { tv } from "@otomoro/helpers/mergeClasses";
import { VariantProps } from "tailwind-variants";

export const productCard = tv({
	slots: {
		base: "relative group flex-[0_0_calc(100%/1.43)] md:flex-[0_0_calc(100%/2.25-6.25rem)] xl:flex-[0_0_calc(100%/3.25-6.25rem)] min-w-[0] flex flex-col flex-shrink-0",
		hoverImage: "w-full h-full absolute top-[0] left-[0] opacity-0 group-hover:opacity-100 transition-opacity object-cover",
		contentAndLink: "flex justify-between mt-[2rem] gap-x-6",
		content: "flex flex-col flex-grow-0",
		titleText: "w-fit group-hover:text-mainPink",
		descriptionText: "group-hover:text-mainPink",
		button: "hidden md:flex group-hover:opacity-100 opacity-0 transition-opacity",
		figure: "border border-onyx rounded-l overflow-hidden relative aspect-[23/26] md:aspect-[35/40] flex justify-center items-center",
		svgLogo: "w-[40.78%]",
	},
	variants: {
		theme: {
			dark: {
				titleText: "text-white",
				descriptionText: "text-white",
			},
			light: {
				titleText: "text-black",
				descriptionText: "text-black",
			}
		}
	}
})

export type ProductCardVariants = VariantProps<typeof productCard>
